import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import url from './utils/config.js'
import $ from 'jquery';

// import axios from 'axios';

// app.prototype.$axios = axios;
// // 有赞
import Vant from 'vant'
import 'vant/lib/index.css'
import upsdk  from 'upsdk';
import { Search } from 'vant';
import { Lazyload } from 'vant';
import { AddressEdit } from 'vant';
import { SwipeCell } from 'vant';
import { ActionSheet  } from 'vant';

import { CouponCell, CouponList } from 'vant';

import { Toast } from 'vant';


const app = createApp(App);
  
app.config.globalProperties.url = url;
app.use(store)
    .use(router)
    .use(Vant)
	.use(Search)
	.use(Lazyload)
	.use(SwipeCell)
	.use(ActionSheet)
	.use(Toast)
	.use(CouponCell)
	.use(CouponList)	
    .mount('#app')

