const state = {
  footer: true,
  city:JSON.parse(localStorage.getItem('visitCity'))?JSON.parse(localStorage.getItem('visitCity')):''
}

const mutations = {
  SHOW_FOOTER(state) {
    state.footer = true
  },
  HIDE_FOOTER(state) {
    state.footer = false
  },
  LOCATION_CITY(state,city){
	   state.city = city
	  
	  }
}

const actions = {

}

export default {
  state,
  mutations,
  actions
}
