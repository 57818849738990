<template>
  <van-tabbar
    v-model="active"
    active-color="#ffa000"
    :route="true"
  >
    <van-tabbar-item
      v-for="(tab, key) in tabs"
      :key="key"
      :name="tab.name"
      :to="tab.to"
      :icon="tab.icon"
    >
      {{ tab.title }}
    </van-tabbar-item>
  </van-tabbar>
</template>

<script>
export default {
  name: 'Footer',
  data() {
    return {
      active: 'Home',
      tabs: [
        {
          'name': 'Home',
          'to': '/',
          'icon': 'wap-home-o',
          'title': '首页'
        },
		{
          'name': 'Shop',
          'to': '/shop',
          'icon': 'shop-o',
          'title': '店铺'
        },
		{
		  'name': 'Discount',
		  'to': '/discount',
		  'icon': 'coupon-o',
		  'title': '优惠'
		},
		{
		  'name': 'shopCart',
		  'to': '/shopCart',
		  'icon': 'cart-o',
		  'title': '购物车'
		},
		// {
		//   'name': 'Information;',
		//   'to': '/information',
		//   'icon': 'comment-o',
		//   'title': '消息'
		// },
		{
          'name': 'Mine',
          'to': '/mine',
          'icon': 'contact',
          'title': '我的'
        }
      ]
    }
  }
}
</script>

<style scoped>

</style>
