<template>
	
		 <router-view v-slot="{ Component }" v-if="isRouterAlive">
		    <keep-alive>
		      <component :is="Component"  v-if="$route.meta.keepAlive"/>
		    </keep-alive>
		    <component :is="Component"  v-if="!$route.meta.keepAlive"/>
		  </router-view> 
		
		<!-- <router-view /> -->
		<Footer v-show="footer" />
		<!--    <float-button v-show="!footer" />-->
	
</template>
<script>
	import Footer from '@/components/Footer'
	// import FloatButton from "@/components/FloatButton"
	import {
		mapGetters
	} from 'vuex'
	import upsdk from 'upsdk-vue'
	import {
		config
	} from "@/api/login";
	import {
		isUnionPay
	} from '@/utils/tools'
	import WebSocket from '@/utils/socket'
	import mitt from 'mitt'

	export default {
		components: {
			Footer
		},
		computed: {
			...mapGetters([
				'footer'
			])
		},
		provide () {    //父组件中通过provide来提供变量，在子组件中通过inject来注入变量。                                             
		            return {
		                reload: this.reload                                              
		            }
		        },
		
		data() {
			return {
				ws: null,
				mitt: null,
				isRouterAlive: true 
			}
		},
		mounted() {
			
			// 云闪付环境配置
			if (isUnionPay()) {
				config({
					url: window.location.href
				}).then((res) => {
					upsdk.config({
						appId: res.data.appId,
						timestamp: res.data.timestamp,
						nonceStr: res.data.nonceStr,
						signature: res.data.signature,
						debug: res.data.debug
					})
				})
				// 标题颜色
				upsdk.ready(function() {
					upsdk.setTitleStyle({
						navBackgroundColor: '0x8FFFFFFF',
						appletStyle: 'black',
						backBtnVisible: 1
					})
				});
			}
			// this.ws = new WebSocket()
			// this.mitt = mitt()
			// this.ws.channel(this.mitt)
			// this.mitt.on('message', data => {
			//   this.messagePush(data)
			// })
		},
		methods: {
			reload () {
			                this.isRouterAlive = false;            //先关闭，
			                this.$nextTick(function () {
			                    this.isRouterAlive = true;         //再打开
			                }) 
			},
			messagePush(data) {
				console.log("收到消息", data)
			}
		}
	}                                                   
</script>
<style>
	.van-swipe-cell__left,
	.van-swipe-cell__right {
		background-color: none !important;
	}

	.van-swipe-cell__wrapper {
		display: flex;
	}

	.van-swipe-cell__left,
	.van-swipe-cell__right {
		top: 20% !important;
		height: 0 !important;
	}

	#app {
		background: #f6f6f6;

	}
 
	html,
	body,
	#app {
		height: 100%;
	}
</style>
