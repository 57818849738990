import {
	createRouter,
	createWebHistory
} from 'vue-router'
import $store from '../store'
import {
	buildUrl
} from '@/utils/tools'

const routes = [{
		path: '/',
		name: 'Home',
		meta: {
			footer: true,
			title: '首页',
			isLogin: true
		},
		component: () => import('../views/home/Home.vue')
	},
	{
		path: '/searchPage',
		name: 'searchPage',
		meta: {
			title: '搜索',
			isLogin: true
		},
		component: () => import('../views/home/searchPage.vue')
	},
	{
		path: '/shop',
		name: 'Shop',
		meta: {
			footer: true,
			title: '店铺',
			isLogin: true
		},
		component: () => import('../views/home/Shop.vue'),
	},

	{
		path: '/discount',
		name: 'Discount',
		meta: {
			footer: true,
			title: '优惠',
			isLogin: true
		},
		component: () => import('../views/home/Discount.vue')
	},
	{
		path: '/discountDetails',
		name: 'DiscountDetails',
		meta: {
			footer: false,
			title: '优惠详情',
			isLogin: true
		},
		component: () => import('../views/home/DiscountDetails.vue')
	},
	{
		path: '/userCoupon',
		name: 'UserCoupon',
		meta: {
			footer: false,
			title: '我的卡券',
			isLogin: true
		},
		component: () => import('../views/mine/userCoupon.vue')
	},

	{
		path: '/information',
		name: 'Information',
		meta: {
			footer: true,
			title: '消息',
			isLogin: true
		},
		component: () => import('../views/home/Information.vue')
	},
	{
		path: '/mine',
		name: 'Mine',
		meta: {
			footer: true,
			title: '我的',
			isLogin: true
		},
		component: () => import('../views/mine/Mine.vue')
	},
	{
		path: '/setting',
		name: 'Setting',
		meta: {
			title: '设置',
			isLogin: true
		},
		component: () => import('../views/mine/Setting.vue')
	},
	{
		path: '/addressUser',
		name: 'AddressUser',
		meta: {
			title: '地址列表',
			isLogin: true
		},
		component: () => import('../views/mine/addressUser.vue')
	},
	{
		path: '/shopDetail',
		name: 'ShopDetail',
		meta: {
			title: '店铺详情',
			isLogin: true
		},
		component: () => import('../views/shop/shopDetail.vue')
	},
	{
		path: '/address',
		name: 'Address',
		meta: {
			title: '地址',
			isLogin: true
		},
		component: () => import('../views/home/address.vue')
	},
	{
		path: '/shoppingCart',
		name: 'ShoppingCart',
		meta: {
			title: '购物车',
			isLogin: true
		},
		component: () => import('../views/shop/shoppingCart.vue')
	},
	{
		path: '/shopCart',
		name: 'shopCart',
		meta: {
			title: '购物车',
			footer: true,
			isLogin: true
		},
		component: () => import('../views/shop/shopCart.vue')
	},
	{
		path: '/orderConfirm',
		name: 'OrderConfirm',
		meta: {
			title: '订单确认',
			isLogin: true
		},
		component: () => import('../views/order/orderConfirm.vue')
	},
	{
		path: '/myOrders',
		name: 'MyOrders',
		meta: {
			title: '我的订单',
			isLogin: true,
			keepAlive: true
		},
		component: () => import('../views/order/myOrders.vue')
	},
	{
		path: '/updateUser',
		name: 'updateUser',
		meta: {
			title: '修改信息',
			isLogin: true
		},
		component: () => import('../views/mine/updateUser.vue')
	},

	{
		path: '/orderDetail',
		name: 'OrderDetail',
		meta: {
			title: '订单详情',
			isLogin: true
		},
		component: () => import('../views/order/orderDetail.vue')
	},
{
		path: '/evaluate',
		name: 'evaluate',
		meta: {
			title: '评价',
			isLogin: true
		},
		component: () => import('../views/order/evaluate.vue')
	},

	{
		path: '/goodsDetail',
		name: 'GoodsDetail',
		meta: {
			title: '商品详情',
			isLogin: true
		},
		component: () => import('../views/goods/goodsDetail.vue')
	},
	{
		path: '/addressEdit',
		name: 'AddressEdit',
		meta: {
			title: '地址编辑',
			isLogin: true
		},
		component: () => import('../views/mine/addressEdit.vue')
	},
	{
		path: '/product/info',
		name: 'OrderInfo',
		meta: {
			title: '订单详情',
			isLogin: true
		},
		component: () => import('../views/order/OrderInfo.vue')
	},

	{
		path: '/chat',
		name: 'ChatList',
		meta: {
			footer: true,
			title: '客服列表',
			isLogin: true
		},
		component: () => import('../views/chat/ChatList.vue')
	},
	{
		path: '/chatPage',
		name: 'chatPage',
		meta: {
			footer: false,
			title: '聊天页',
			isLogin: true
		},
		component: () => import('../views/chat/ChatPage.vue'),
		// beforeEach:((to, from, next) => {
		//   console.log(to.query.storeName)
		//   console.log(to.meta)
		//   to.meta.title = to.query.storeName
		//   console.log(to.meta.title)
		//   next()
		// })

	},
	{
		path: '/collect',
		name: 'Collect',
		meta: {
			footer: false,
			title: '收藏列表',
			isLogin: true
		},
		component: () => import('../views/mine/collect.vue')
	},
	{
		path: '/history',
		name: 'History',
		meta: {
			footer: false,
			title: '浏览记录',
			isLogin: true
		},
		component: () => import('../views/mine/browsingHistory.vue')
	},
	{
		path: '/concern',
		name: 'Concern',
		meta: {
			footer: false,
			title: '关注列表',
			isLogin: true
		},
		component: () => import('../views/mine/ConcernList.vue')
	},
	{
		path: '/login',
		name: 'Login',
		meta: {
			footer: false,
			title: '登录'
		},
		component: () => import('../views/Login.vue')
	},
	{
		path: '/:catchAll(.*)',
		name: '404',
		meta: {
			footer: false,
			title: '404'
		},
		component: () => import('../views/404/404.vue')
	}
]

const router = createRouter({
	history: createWebHistory(),
	routes
})

router.beforeEach((to, form, next) => {
	const {
		title,
		footer,
		isLogin
	} = to.meta
	// 是否需要登录
	console.log(to)
	if (isLogin === true && !$store.state.user.isLogin) {
		$store.dispatch('ToLogin', buildUrl(to.href))
	}

	// 标题
	document.title = title
	// 底部导航是否显示
	footer === true ? $store.commit('SHOW_FOOTER') : $store.commit('HIDE_FOOTER')
	if (to.query.storeName) {
		console.log(to.query.storeName)
		document.title = to.query.storeName
		// next()
	}
	next()
})

export default router
